import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/_development/preview/package": [~4,[2]],
		"/_development/preview/post": [~5,[2]],
		"/_development/preview/training": [~6,[2]],
		"/about": [~7],
		"/legal": [8],
		"/news": [~9],
		"/packages": [~10],
		"/packages/[id]": [~11],
		"/posts": [~12],
		"/posts/[id]": [~13],
		"/privacy": [14],
		"/training": [~15],
		"/training/list": [~17],
		"/training/[id]": [~16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';